import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'component-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  navigate: any[];
  page: string = 'home';
  public isMenuCollapsed = true;
  constructor(
    private router: Router
  ) {
    router.events.subscribe((router: any) => {      
      let currentURL = (router.url ? router.url.split('/')[1] : this.page);
      this.page = (!currentURL ? this.page : currentURL);
    });

  }

  ngOnInit() {
    this.navigate = [
      {
        title: 'home',
        name: 'Home',
        active: true
      }, {
        title: 'about',
        name: 'About',
        active: true
      }, {
        title: 'course',
        name: 'Course',
        active: true
      }, {
        title: 'feedback',
        name: 'Feedback',
        active: true
      }, {
        title: 'food-supplement',
        name: 'Blog',
        active: true
      }, {
        title: 'contact',
        name: 'Contact',
        active: true
      }
    ];
  }

  changePage(nav) {
    if (nav.active) {
      this.isMenuCollapsed= !this.isMenuCollapsed;
      this.router.navigateByUrl('/' + nav.title + '');
      this.page = nav.title;
    }
  }
}
