import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { FooterComponent } from './footer.component';

const routes: Routes = [
    {
        path: '',
        component: FooterComponent
    }
];

@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forChild(routes)
    ],
    exports: [FooterComponent],
    providers: [],
    declarations: [FooterComponent],
    bootstrap: [FooterComponent]
})
export class FooterModule {

}
