import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as moment from 'moment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-food-supplement-detail',
  templateUrl: './food-supplement-detail.component.html',
  styleUrls: ['./food-supplement-detail.component.scss']
})
export class FoodSupplementDetailComponent implements OnInit {
  supplyList: any[];
  supplyDetail: any;

  constructor(
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private title: Title,
    private meta: Meta
  ) {
    this.firebaseService.food_supplement.subscribe((result) => {
      console.log('food_supplement: ', result);
      this.supplyList = result ? result.data : [];
      this.route.paramMap.subscribe((params: any) => {
        console.log(params.params);

        this.supplyList.forEach((s) => {
          if (s.id == params.params.food_id) {
            this.supplyDetail = s;

            this.title.setTitle(s.title);
            this.meta.addTags([
              { name: 'og:type', content: 'article' },
              { name: 'og:url', content: window.location.href },
              { name: 'og:title', content: s.title },
              { name: 'og:description', content: s.title },
              { name: 'og:image', content: s.image }
            ]);
            // this.meta.updateTag({ property: 'og:url', content: window.location.href }, 'property="og:url"');
            // this.meta.updateTag({ property: 'og:type', content: 'website' }, 'property="og:type"');
            // this.meta.updateTag({ property: 'og:title', content: s.title }, 'property="og:title"');
            // this.meta.updateTag({ property: 'og:description', content: s.title }, 'property="og:description"');
            // this.meta.updateTag({ property: 'og:image:url', content: s.image }, 'property="og:image:url"');
            // this.meta.updateTag({ property: 'og:image:secure_url', content: s.image }, 'property="og:image:secure_url"');
          }
        });
      });
      console.log(this.supplyDetail);
    });
  }

  ngOnInit() {

  }

  convertFormatDate(date) {
    return moment(date, 'DD/MM/YYYY').format('DD.MM.YYYY');
  }

  async sharelink(social_type) {
    console.log('shared', social_type);

    if (social_type == 'fb') {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href);
    } else if (social_type == 'tw') {
      window.open('https://twitter.com/intent/tweet?&url=' + window.location.href);
    }
  }

  back() {
    window.history.back();
  }

}
