import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
@Component({
    selector: 'app-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
    courseList: any[];
    constructor(
        private router: Router,
        private firebaseService: FirebaseService
    ) {
    }

    ngOnInit() {
        this.firebaseService.main_course.subscribe((result) => {
            console.log('main_course: ', result);
            this.courseList = result? result.data : [];
        });
    }

    courseDetail(course, day) {
        console.log(course);
        course.day = day;
        this.router.navigateByUrl('course/c/'+course.id,{state: {data: course}})
    }
}
