import { Component, OnInit } from '@angular/core';
import { FirebaseService } from './services/firebase.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'kanlaya';

  constructor(public firebaseService: FirebaseService, private meta: Meta) {
    this.meta.addTag({ property: 'og:url', content: 'https://kanlaya-eco.com/' });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:title', content: 'Kanlaya Eco Thinking' });
    this.meta.addTag({ property: 'og:description', content: 'เป็นสถาบันฝึกอมรม และพัฒนาบุคลากร ด้านกระบวนการคิดในรูปแบบต่างๆ อาทิ การคิดวิเคราะห์ การคิดเชิงระบบ การคิดเชิงกลยุทธ์ การคิดสร้างสรรค์ และการคิดเชิงการออกแบบ เป็นต้น' });
    this.meta.addTag({ property: 'og:image:url', content: 'https://kanlaya-eco.com/assets/img/home/logo_name.png' });
    this.meta.addTag({ property: 'og:image:secure_url', content: 'https://kanlaya-eco.com/assets/img/home/logo_name.png' });
  }
  async ngOnInit(): Promise<void> {
    await this.firebaseService.loginFirebase();
  }
}
