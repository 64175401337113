import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterModule } from './component/footer/footer.module';
import { HomeComponent } from './page/home/home.component';
import { ContactComponent } from './page/contact/contact.component';
import { NavBarModule } from './component/nav-bar/nav-bar.module';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { FirebaseConfig } from './firebase.config';
import { FirebaseService } from './services/firebase.service';
import { CourseComponent } from './page/course/course.component';
import { CourseDetailComponent } from './page/course-detail/course-detail.component';
import { FoodSupplementComponent } from './page/food-supplement/food-supplement.component';
import { FoodSupplementDetailComponent } from './page/food-supplement-detail/food-supplement-detail.component';
import { AboutComponent } from './page/about/about.component';
import { FeedbackComponent } from './page/feedback/feedback.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    CourseComponent,
    CourseDetailComponent,
    FoodSupplementComponent,
    FoodSupplementDetailComponent,
    AboutComponent,
    FeedbackComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    AngularFireModule.initializeApp(FirebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    NgbModule,
    FooterModule,
    NavBarModule,
  ],
  providers: [FirebaseService],
  bootstrap: [AppComponent]
})
export class AppModule { }
