import { Injectable } from '@angular/core';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import * as moment from 'moment';
import { _ } from 'underscore';

export enum Status {
  Pending,
  Approve,
  Reject
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  databaseVersion = 'v3';

  image_slide$: Observable<any>;
  image_slide: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  main_course$: Observable<any>;
  main_course: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  food_supplement$: Observable<any>;
  food_supplement: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  feedback$: Observable<any>;
  feedback: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public afAuth: AngularFireAuth, public db: AngularFirestore) {

  }

  async loginFirebase() {

    await this.afAuth.auth.signInAnonymously();
    let user = this.afAuth.authState;
    console.log("user: ", user);

    this.initData();
  }

  async initData() {
    this.getDataImageSlide();
    this.getDataMainCourse();
    this.getDataFoodSupplement();
    this.getDataFeedback();
  }

  getDataImageSlide() {
    this.image_slide$ = this.db.collection(this.databaseVersion).doc('image_slide').valueChanges();
    this.image_slide$.subscribe(v => this.image_slide.next(v));
  }

  getDataMainCourse() {
    this.main_course$ = this.db.collection(this.databaseVersion).doc('main_course').valueChanges();
    this.main_course$.subscribe(v => this.main_course.next(v));
  }

  getDataFoodSupplement() {
    this.food_supplement$ = this.db.collection(this.databaseVersion).doc('food_supplement').valueChanges();
    this.food_supplement$.subscribe(v => this.food_supplement.next(v));
  }

  getDataFeedback() {
    this.feedback$ = this.db.collection(this.databaseVersion).doc('feedback').valueChanges();
    this.feedback$.subscribe(v => {
      this.feedback.next(v)
    });
  }

  async setDataFeedback(data) {
    console.log('setDataFeedback: ', data);
    if (this.feedback$) {
      let dataFeedback = await this.feedback.getValue();
      console.log('feedback.getValue: ', dataFeedback);
      if (_.isEmpty(dataFeedback)) {
        dataFeedback = {
          data: [
            {
              timestamp: String(moment().unix()),
              name: data.name,
              company: data.company,
              msg: data.msg,
              image: data.image,
              status: Status.Pending
            }
          ]
        }
      } else {
        dataFeedback.data.push(
          {
            timestamp: String(moment().unix()),
            name: data.name,
            company: data.company,
            msg: data.msg,
            image: data.image,
            status: data.status
          }
        )
      }
      console.log('dataFeedback: ', dataFeedback);

      this.db.collection(this.databaseVersion).doc('feedback').set(dataFeedback)
        .then((res) => {
          console.log('Document written: ', res);
        }).catch((error) => {
          console.error('Error adding document: ', error);
        });
    }
  }
}

