import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
    selector: 'app-course-detail',
    templateUrl: './course-detail.component.html',
    styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {
    courseList: any[];
    courseDetail: any;
    constructor(
        private route: ActivatedRoute,
        private firebaseService: FirebaseService
    ) {
    }

    ngOnInit() {
        console.log(window.history.state.data);
        if (window.history.state.data) {
            this.courseDetail = window.history.state.data;
        } else {
            this.firebaseService.main_course.subscribe((result) => {
                console.log('main_course: ', result);
                this.courseList = result ? result.data : [];
                this.route.paramMap.subscribe((params: any) => {
                    console.log(params.params);
                    this.courseList.forEach((c) => {
                        console.log("c", c);
                        for (let i = 0; i < c.data.length; i++) {
                            if (c.data[i].id == params.params.course_id) {
                                this.courseDetail = c.data[i];
                                this.courseDetail.day = c.groupName
                            }
                        }
                        console.log(this.courseDetail);
                    });
                });
            });
        }
    }

    back() {
        window.history.back();
    }

}
