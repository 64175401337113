import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { FoodSupplementDetailComponent } from '../food-supplement-detail/food-supplement-detail.component';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-food-supplement',
  templateUrl: './food-supplement.component.html',
  styleUrls: ['./food-supplement.component.scss']
})
export class FoodSupplementComponent implements OnInit {

  supplyList: any[];
  constructor(
    private router: Router,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    this.firebaseService.food_supplement.subscribe((result) => {
      console.log('food_supplement: ', result);
      this.supplyList = result ? result.data : [];
    });
  }

  openDetail(data) {
    console.log(data);
    
    this.router.navigateByUrl('food-supplement/f/'+ data.id);
  }
}
