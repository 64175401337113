import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { NavBarComponent } from './nav-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
    {
        path: '',
        component: NavBarComponent
    }
];

@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forChild(routes),
        NgbModule
    ],
    exports: [NavBarComponent],
    providers: [],
    declarations: [NavBarComponent],
    bootstrap: [NavBarComponent]
})
export class NavBarModule {

}
