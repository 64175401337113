import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { _ } from 'underscore';
import { FirebaseService, Status } from '../../services/firebase.service';


@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
    feedbackList: any[];
    lastFeedback: any;
    feedbackForm: any;
    corpList: any[];
    constructor(
        private firebaseService: FirebaseService,
        private modalService: NgbModal
    ) {
        this.feedbackForm = {
            name: '',
            company: '',
            msg: '',
            image: '',
            status: Status.Pending
        }


    this.corpList = [
        'assets/img/home/logo_customer1.png',
        'assets/img/home/logo_customer2.png',
        'assets/img/home/logo_customer3.png',
        'assets/img/home/logo_customer4.png',
        'assets/img/home/logo_customer5.png',
        'assets/img/home/logo_customer6.png',
        'assets/img/home/logo_customer7.png'
      ]
    }

    async ngOnInit() {
        this.firebaseService.feedback.subscribe((result: any) => {
            let dataFeedback = (result && result.data) ? result.data : [];
            console.log('#dataFeedback: ', dataFeedback);
            this.filterApprove(dataFeedback);
        })
    }

    filterApprove(dataFeedback) {
        if (dataFeedback) {
            const filterResult = dataFeedback.filter((f) => {
                return f.status == Status.Approve;
            })
            filterResult.forEach(f => {
                f.date = moment.unix(f.timestamp).format('DD/MM/YYYY HH:mm');
            });
            this.feedbackList = _.sortBy(filterResult, (f) => {
                return f.date;
            }).reverse()
        } else {
            this.feedbackList = dataFeedback;
        }
    }

    submitFeedback(content) {
        if (this.feedbackForm.name && this.feedbackForm.company && this.feedbackForm.msg) {
            this.firebaseService.setDataFeedback(this.feedbackForm).then(() => {
                console.log('submit success');
                this.clearFeedbackForm();
                this.openModalSuccess(content);
            });
        }
    }

    openModalSuccess(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })

        setTimeout(()=>{
            this.modalService.dismissAll();
        },4000)
    }

    clearFeedbackForm() {
        this.feedbackForm = {
            name: '',
            company: '',
            msg: '',
            image: '',
            status: Status.Pending,
            timestamp: ''
        }
    }
}
