import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imagesSlide: any[] = [];
  menu: any[] = [];
  courseList: any[] = [];
  supplyList: any[] = [];
  corpList: any[] = [];

  constructor(
    private router: Router,
    private firebaseService: FirebaseService
  ) {

  }

  ngOnInit() {
    this.menu = [
      'assets/img/home/menu1.png',
      'assets/img/home/menu2.png',
      'assets/img/home/menu3.png'
    ];

    this.corpList = [
      'assets/img/home/logo_customer1.png',
      'assets/img/home/logo_customer2.png',
      'assets/img/home/logo_customer3.png',
      'assets/img/home/logo_customer4.png',
      'assets/img/home/logo_customer5.png',
      'assets/img/home/logo_customer6.png',
      'assets/img/home/logo_customer7.png'
    ]

    this.firebaseService.image_slide.subscribe((result) => {
      console.log('image_slide: ', result);
      this.imagesSlide = result && result.data.length > 0 ? result.data : [];
    });

    this.firebaseService.main_course.subscribe((result) => {
      console.log('main_course: ', result);
      let courseList: any[] = [];
      courseList = result && result.data.length > 0 ? result.data : [];
      courseList.forEach(coursePerDay => {
        coursePerDay.data.forEach(data => {
          if (this.courseList.length >= 3) {
            console.log('courseList: ', this.courseList);
            return;
          } else {
            this.courseList.push(data);
          }
        });
      });
    });

    this.firebaseService.food_supplement.subscribe((result) => {
      console.log('food_supplement: ', result);
      this.supplyList = (result && result.data) ? result.data : [];
    });
  }

  showMore(link) {
    this.router.navigateByUrl('/' + link + '');
  }

  courseDetail(course) {
    console.log('courseDetail: ', course);
    this.router.navigateByUrl('course/c/' + course.id)
  }

  openSupplementDetail(supp) {
    this.router.navigateByUrl('food-supplement/f/' + supp.id);
  }

}
