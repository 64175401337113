import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { ContactComponent } from './page/contact/contact.component';
import { CourseComponent } from './page/course/course.component';
import { FoodSupplementComponent } from './page/food-supplement/food-supplement.component';
import { FoodSupplementDetailComponent } from './page/food-supplement-detail/food-supplement-detail.component';
import { CourseDetailComponent } from './page/course-detail/course-detail.component';
import { AboutComponent } from './page/about/about.component';
import { FeedbackComponent } from './page/feedback/feedback.component';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // {
  //   path: 'home',
  //   loadChildren: () => HomeModule
  // },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'course',
    children: [
      {
        path: '',
        component: CourseComponent,
        pathMatch: 'full'
      },
      {
        path: 'c/:course_id',
        component: CourseDetailComponent
      }
    ]
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'food-supplement',
    children: [
      {
        path: '',
        component: FoodSupplementComponent,
        pathMatch: 'full'
      },
      {
        path: 'f/:food_id',
        component: FoodSupplementDetailComponent
      }
    ]
  },
  {
    path: 'contact',
    component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: false, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
